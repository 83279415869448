import React, {useState, useEffect} from "react";
import Unity, {UnityContext} from "react-unity-webgl";
import Modal from 'react-modal';
import axios from 'axios'
import './App.css';
import MintButton from './MintButton'
import Web3 from "web3";
import NFTwindow from "./components/NFTwindow";

import placeholder from './img/placeholder.png'
import logo from './img/logo.png'
import howtoplay from './img/howtoplay.png'
import {FortuneWeth, MononokeWeth, v2PairAbi, ContractAbi, contractAddress} from "./config";

const targetNetworkId = '0x1';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#5a3434',
        border: '1px solid rgb(36 16 3)',

    }
};

const unityContext = new UnityContext({
  loaderUrl: "/8june/Build.loader.js",
  dataUrl: "/8june/Build.data",
  frameworkUrl: "/8june/Build.framework.js",
  codeUrl: "/8june/Build.wasm",
});

function App() {
//NFT viewerWeb3(Web3.givenProvider)
const web3 = new Web3(new Web3.providers.HttpProvider(`https://mainnet.infura.io/v3/5ba54d50e2bd417ab40e202111c8719e`))
const [mintAmount, _setMintAmount] = useState(1);
    const [balance, setBalance] = useState([]);
    const [address, setAddress] = useState();
    const [discounted, _setDiscounted] = useState(false);
    const [nonDiscountPrice, setnonDiscountPrice] = useState(0);
    const [DiscountPrice, setDiscountPrice] = useState(0);
    const [minHold, setMinHold] = useState([]);
    const contract = new web3.eth.Contract(ContractAbi, contractAddress);


    const fortuneWeth = new web3.eth.Contract(v2PairAbi, FortuneWeth)
    const mononokeWeth = new web3.eth.Contract(v2PairAbi, MononokeWeth)
    const [accounts, _setAccounts] = useState([]);
    const [progression, setProgression] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    const [active, setActive] = useState('playgame');
    const [saitokiPrice, setSaitokiPrice] = useState(0)
    const [MononokeInuPrice, setMononokeInuPrice] = useState(0)
    const [fortunePrice, setFortunePrice] = useState(0)
    function isConnected() { return Boolean(accountsRef.current[0]); }

    // We need useRef to access useStates from the Unity listeners, otherwise you get back default values only
    const accountsRef = React.useRef(accounts);
    const setAccounts = data => {
        accountsRef.current = data;
        _setAccounts(data);
    }
    const mintAmountRef = React.useRef(mintAmount);
    const setMintAmount = data => {
        mintAmountRef.current = data;
        _setMintAmount(data);
    }
    const discountedRef = React.useRef(discounted);
    const setDiscounted = data => {
        discountedRef.current = data;
        _setDiscounted(data);
    }

    

      async function connectAccount() {
          if (window.ethereum) {
            const accounts = await window.ethereum.request({
              method: "eth_requestAccounts",
            });
            setAccounts(accounts);
            console.log("setAccounts " + accounts);
          }
      }


//"https://min-api.cryptocompare.com/data/pricemultifull?fsyms=BNB&tsyms=USD"

    if(window.ethereum){
    
    window.ethereum.on("accountsChanged", (e, r) => {
        console.log("accountsChanged, window reloading...");
        window.location.reload()
    })
}

    useEffect(function () {
        async function abc() {

            if (window.ethereum) {
                const currentChainId = await window.ethereum.request({
                  method: 'eth_chainId',
                });
            
                // return true if network id is the same
                if (currentChainId != targetNetworkId){
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: targetNetworkId }],
                      });
                      // refresh
                      window.location.reload();

                }

              }

              const address = await web3.eth.getAccounts()
              setAddress(address[0])
              const result = await contract.methods.hasDiscount(address[0]).call()
              setDiscounted(result)
              const price1 = await contract.methods.PRICE_PER_TOKEN_PUBLIC_SALE().call()
              setnonDiscountPrice(web3.utils.fromWei(price1,"ether"))
              const price2 = await contract.methods.PRICE_PER_TOKEN_FOR_HOLDERS().call()
              setDiscountPrice(web3.utils.fromWei(price2,"ether"))
              const holdAmount = await contract.methods.MINIMUM_HOLD_AMOUNT().call()
              setMinHold(holdAmount)
        }

        abc()

        unityContext.on("progress", (progression) => {
            setProgression(progression);
        });


        fortuneWeth.methods.getReserves().call((e, r) => {
            var amountInWithFee = 1000000000 * 998;
            var numerator = amountInWithFee * Number(r._reserve1);
            var denominator = (Number(r._reserve0) * 1000) + amountInWithFee;
            var amountOut = numerator / denominator;
            setFortunePrice(amountOut / 1000000000000000000)
        })


        mononokeWeth.methods.getReserves().call((e, r) => {
            var amountInWithFee = 1000000000 * 998;
            var numerator = amountInWithFee * Number(r._reserve1);
            var denominator = (Number(r._reserve0) * 1000) + amountInWithFee;
            var amountOut = numerator / denominator;
            setMononokeInuPrice(amountOut / 1000000000000000000)
        })


        axios.get("https://api.nomics.com/v1/currencies/ticker?key=72146beb1471f7d3ce37f31057449472a8a5cbef&ids=BTC,SAITOKI,XRP&interval=1d,30d&convert=ETH&platform-currency=ETH&per-page=100&page=1")
            //   // Handle a successful response from the server
            .then(response => {
//           // Getting a data object from response that contains the necessary data from the server
                const data = response.data;
                setSaitokiPrice(response.data[0].price)
//           // Save the unique id that the server gives to our object

            })
            //   // Catch and print errors if any
            .catch(error => console.error('On create student error', error));


        connectAccount()


        // Interactions with Unity game
        unityContext.on("isConnected",() => {
            console.log("From React:");
            console.log("   accountsRef.current: " + accountsRef.current);
            let connected = 0;
            if (isConnected())
                connected = 1;
            unityContext.send("NFTLibrary", "IsConnected", connected);
        });

        unityContext.on("handleIncrement", () => {
            handleIncrement();
        });

        unityContext.on("handleDecrement", () => {
            handleDecrement();
        });

        unityContext.on("handleMint", () => {
            handleMint();
        });

        return function () {
            unityContext.removeAllEventListeners();
        };

    }, []);


    async function handleMint() {
        if (mintAmountRef.current <= 0)
            setMintAmount(1);

            var value = discountedRef.current? mintAmountRef.current * 0.06 : mintAmountRef.current * 0.08

        console.log("handleMint()");
        console.log('discount: ', discountedRef)
        console.log("  value", value);
        console.log("  account", accountsRef.current[0]);

        if (window.ethereum) {
            const web32 = new Web3(Web3.givenProvider)      // the URL at the top of this class didn't work...
            const contract2 = new web32.eth.Contract(ContractAbi,contractAddress)

            console.log("  contractAddress", contractAddress);
            await contract2.methods.mint(mintAmountRef.current).send({
                from: accountsRef.current[0],
                value: web3.utils.toWei(value.toString(), "ether"),
                gasLimit: 300000*mintAmountRef.current
            })
        }
    }

    const handleDecrement = () => {
        if (mintAmountRef.current <= 1) return;
        setMintAmount(mintAmountRef.current - 1)
    }
    const handleIncrement = () => {
        if (mintAmountRef.current >= 5) return;
        setMintAmount(mintAmountRef.current + 1)
    }

    console.log("Saitoki", saitokiPrice)
    console.log("Fortune", fortunePrice)
    console.log("Mononoke", MononokeInuPrice)

    function handleOnClickFullscreen() {
        unityContext.setFullscreen(true);
    }

    const setModalIsOpenToTrue = () => {
        setModalIsOpen(true)
    }

    const setModalIsOpenToFalse = () => {
        setModalIsOpen(false)
    }
/*
    async function connectAccount() {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            setAccounts(accounts);
            console.log("setAccounts " + accounts);
        }
    }
*/
    function gameActive() {
        setActive('playgame');
        window.location.reload()
    }

    function nftActive() {
        setActive('nftviewer');
    }

    function mintActive() {
        setActive('minter');
    }


    return (
        <div className="App">

            <div className='viewport'>
                <Modal closeTimeoutMS={200} isOpen={modalIsOpen} style={modalStyles}
                       onRequestClose={() => setModalIsOpen(false)}>
                    <img src={howtoplay}/>
                </Modal>

                <img src={logo} alt='Saitoki, Remnants of the Night' className='logo'/>
                <div className='centerfold'>
                    <button className="connectWalletButton" onClick={connectAccount}>
                        {isConnected() ? (
                            <p>Connected: ...{accounts[0].substring(38, 42)}</p>
                        ) : (
                            <p>Connect Wallet...</p>
                        )}
                    </button>
                    <div className='gameFrame'>

                        {active === "nftviewer" &&
                        <div className='gameWindow'>
                            <NFTwindow accounts={accounts}/>

                        </div>
                        }

                        {active === "playgame" &&
                        <div className='gameWindow'>
                            {progression !== 1
                            &&
                            <div className='loader'>
                                <div className='loaderText'>
                                    <p>Loading...</p>
                                    <p className='loaderPercent'>{Math.floor(progression * 100)}%</p>
                                </div>
                            </div>
                            }
                            <Unity unityContext={unityContext} className='unityBox'/>
                        </div>
                        }

                        {active === "minter" &&
                        <div className='gameWindow'>
                            <MintButton accounts={accounts} setAccounts={setAccounts}/>


                        </div>
                        }


                    </div>
                    <div className='menuButtons'>
                        <button className='home menu-button'
                                onClick={() => window.open("https://www.saitoki.net/", "_blank")}></button>
                        <button className='telegram menu-button'
                                onClick={() => window.open("https://t.me/saitokiinu", "_blank")}></button>

                        <button className='NFT-viewer menu-button' onClick={nftActive}></button>
                        <button className='playgame menu-button' onClick={gameActive}></button>
                        <button className='minter menu-button' onClick={mintActive}></button>

                        <button className='how-to menu-button' onClick={setModalIsOpenToTrue}></button>
                        <button className='fullscreen menu-button' onClick={handleOnClickFullscreen}></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;


// axios.get("https://api.nomics.com/v1/currencies/ticker?key=72146beb1471f7d3ce37f31057449472a8a5cbef&ids=BTC,SAITOKI,XRP&interval=1d,30d&convert=ETH&platform-currency=ETH&per-page=100&page=1")
// //   // Handle a successful response from the server
//    .then(response => {
// //           // Getting a data object from response that contains the necessary data from the server
//            const data = response.data;
//            setSaitokiPrice(response.data[0].price)
// //           // Save the unique id that the server gives to our object

//    })
// //   // Catch and print errors if any
//    .catch(error => console.error('On create student error', error));

//   //  axios.get(
//   //   `https://rest.coinapi.io/v1/exchangerate/BTC/USD/history?period_id=1MIN&time_start=2022-02-01T00:00:00&time_end=2022-03-01T00:00:00`,{
//   //       "headers": {'X-CoinAPI-Key': "42659797-1BEE-44F0-A72A-0E1F9DA2DB53"}   
//   //   }
//   //  ).then((r)=>{
//   //   setFortunePrice(r.data)   
//   //   console.log("Response",r.data)})
//   axios.get(
//     `https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?start=1&limit=5000&convert=USD`,{
//         "headers": {'X-CMC_PRO_API_KEY': "dd11bdb9-d16e-4018-8de5-4ac4b5bdd7c4"},
//         "Accept": "application/json"   
//     }
//    ).then((r)=>{
//     setFortunePrice(r.data)   
//     console.log("Response",r.data)})
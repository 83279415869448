import { useEffect, useState } from 'react';
import { ethers, BigNumber } from 'ethers';
import Web3 from 'web3';
import { ContractAbi, contractAddress } from './config';


const MintButton = ({accounts, setAccounts}) => {
    const [mintAmount, setMintAmount] = useState(1);
    const [balance, setBalance] = useState([]);
    const [address, setAddress] = useState();
    const [discounted, setDiscounted] = useState(false);
    const [nonDiscountPrice, setnonDiscountPrice] = useState(0);
    const [DiscountPrice, setDiscountPrice] = useState(0);
    const [minHold, setMinHold] = useState([]);

    const web3 = new Web3(new Web3(Web3.givenProvider))
    const contract = new web3.eth.Contract(ContractAbi,contractAddress)
    if(window.ethereum){
    
        window.ethereum.on("accountsChanged", (e, r) => {
            console.log("accountsChanged, window reloading...");
            window.location.reload()
        })
    }    useEffect(()=>{
        async function abc(){
            const address = await web3.eth.getAccounts()
            setAddress(address[0])
            const result = await contract.methods.hasDiscount(address[0]).call()
            setDiscounted(result)
            const price1 = await contract.methods.PRICE_PER_TOKEN_PUBLIC_SALE().call()
            setnonDiscountPrice(web3.utils.fromWei(price1,"ether"))
            const price2 = await contract.methods.PRICE_PER_TOKEN_FOR_HOLDERS().call()
            setDiscountPrice(web3.utils.fromWei(price2,"ether"))
            const holdAmount = await contract.methods.MINIMUM_HOLD_AMOUNT().call()
            setMinHold(holdAmount)
        }

        abc()


    },[])

    const isConnected = Boolean(accounts[0]);

    console.log("address",address)
    console.log("minHold:" + minHold)
    async function handleMint() {

    var value = discounted?   mintAmount * 0.06 : mintAmount * 0.08

        

    console.log("value",discounted)
      if(window.ethereum){
        contract.methods.mint(mintAmount).send({from:accounts[0],value:web3.utils.toWei(value.toString(),"ether"), gasLimit: 300000*mintAmount})    
      }
    }

    const handleDecrement = () => {
        if (mintAmount <= 1 ) return;
        setMintAmount(mintAmount - 1)
    }
    const handleIncrement = () => {
        if (mintAmount >= 5 ) return;
        setMintAmount(mintAmount + 1)
    }

return (
    <div>
        {isConnected ? (
            <div className='mintSection'>
                <p className='mintHeader'>Mint your Saitoki Companions.</p>
                <p>Collect as many companions as you can, and send them on
bounties as often as you can to maximize your bounty rewards.</p>
                <div className='incrementer'>
                    <button className='squareButton' onClick={handleDecrement}>-</button>
                    <input type='number' value={mintAmount} />
                    <button className='squareButton'  onClick={handleIncrement}>+</button>
                </div>
                <button className='mintButton' onClick={handleMint}>Mint Now</button>
            </div>
        ) : (
            <div className='nftViewer'>
            <p className='no-nft'>Connect your wallet to mint</p>
            </div>
        )
    }
    </div>
)
};

export default MintButton;